<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-5 mx-auto">
      <div class="card rounded shadow-lg">
        <div class="card-body">
          <div class="text-center">
            <img src="images/siged2.png" alt="homepage" class="dark-logo" />
          </div>
          <br>
          <div class="card-title text-center">
            <h2>Cierre de sesión por inactividad</h2>
          </div>
          <form > 
            <div class="row text-justify">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-12 col-form-label float-right">Por seguridad se ha cerrado la sesión automáticamente porque no se ha detectado ninguna actividad durante un tiempo.</label>
                  <label class="col-md-12 col-form-label float-right">Vuelva a iniciar la sesión si desea continuar en el sistema.</label>
                </div>
              </div>
            </div>    
            <div class="row text-center">            
              <div class="col-md-12 float-center">
                <router-link :to="{ name: 'Signin' }" class="btn btn-primary float-center">Aceptar</router-link>
              </div>            
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template> 
<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.buttontext = 'Aceptar';     
    },
    methods: {
      setClose(close){
        this.close = close;
      }
    }
  }
</script>
<style>
    .warning {
        color: #ff0000;
    }
</style>
